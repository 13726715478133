module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/codebuild/output/src3185958796/src/sp-website/node_modules/gatsby-remark-line-breaks","id":"0eb1f9e6-007a-573e-bd13-72471f920652","name":"gatsby-remark-line-breaks","version":"1.0.0","modulePath":"/codebuild/output/src3185958796/src/sp-website/node_modules/gatsby-remark-line-breaks/dist/index.js","module":{},"pluginOptions":{"plugins":[],"maxWidth":590},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src3185958796/src/sp-website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Signature Products GmbH","short_name":"Signature Products","description":"Signature Products is the hemp wholesaler for Europe. From hemp raw material to finished hemp products. Production, trade and consulting.","start_url":"/en/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/images/icon.svg","crossOrigin":"use-credentials","localize":[{"start_url":"/de/","lang":"de","name":"Signature Products GmbH (Deutschland)","short_name":"Signature Products (DE)","description":"Signature Products ist der Hanfgroßhandel Europas. Von Hanf-Rohmaterial bis hin zu fertigen Hanf-Produkten. Produktion, Handel und Beratung."},{"start_url":"/es/","lang":"es","name":"Signature Products GmbH (Español)","short_name":"Signature Products (ES)","description":"Signature Products es el mayorista de cáñamo de Europa. Desde la materia prima del cáñamo hasta los productos acabados del mismo. Producción, comercio y consultoría."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b2aeac9ed5c4873d8a903597c6e85755"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
